import MessageItem from "./MessageItem";

export async function postMessage(item: MessageItem) {
	try {				
		const res = await fetch(`${process.env.REACT_APP_SENDMAIL_FUNCTIONURL}`,
		{
			method: "POST",
			headers: {
				'Content-type': 'application/json; charset=UTF-8'	
			},
			body: JSON.stringify(item)
		});  
		
		if (!res.ok) 
		{
			throw new Error(res.statusText);			
		}						
	} 
	catch (error) {
		console.error(error)
		throw new Error(error);					
	}

	return true;
}