import React, { useCallback } from 'react';
import { useAppContext } from '../AppContext';
import ImmoSlider from '../Components/ImmoSlider';

const OptionenPage = () => { 
    const { immoTyp, subTyp, bauJahr, setBaujahr, bauWeise, setBauweise, heizung, setHeizung, nebenObjekte, setNebenobjekte, wohnFlaeche, setWohnflaeche, grundstueckFlaeche, setGrundstueckflaeche } = useAppContext()!;    
    
    const onSetNebenobjekte = useCallback((value: string, checked: boolean) => {
        if (checked) {
            if (nebenObjekte.indexOf(value) >= 0) return;                                
            setNebenobjekte(nebenObjekte.concat(value));
        }
        else setNebenobjekte(nebenObjekte.filter(item => item !== value));        
    }, [nebenObjekte, setNebenobjekte]);

    const onSliderChangeBaujahr = useCallback((value: number) => {        
        setBaujahr(value);
    }, [setBaujahr]);

    const onSliderChangeWohnflaeche = useCallback((value: number) => {        
        setWohnflaeche(value);
    }, [setWohnflaeche]);

    const onSliderChangeGrundstueckflaeche = useCallback((value: number) => {        
        setGrundstueckflaeche(value);
    }, [setGrundstueckflaeche]);

    return (
        <>  
            {(immoTyp === 'Einfamilienhaus' || immoTyp === 'Wohnung') && <>
                <div>                    
                    Baujahr:&nbsp;                     
                    <b>{bauJahr}</b>
                    <div className='immoSlider'><ImmoSlider min={1800} max={new Date().getFullYear()} step={1} value={bauJahr} sliderChange={onSliderChangeBaujahr} /></div>
                </div>
            </>}  
            {(immoTyp === 'Wohnung') && <>
                <div>                    
                    Nebenobjekte:&nbsp;                     
                    <b>{nebenObjekte.join()}</b>
                    <div className={"immoSelect"}>
                        <label><input type="checkbox" id="garten" name="nebenobjekte" value="Garten" checked={nebenObjekte.indexOf("Garten") >= 0} onChange={(e) => onSetNebenobjekte(e.target.value, e.target.checked)} />Garten</label>                                           
                        <label><input type="checkbox" id="terrasse" name="nebenobjekte" value="Terrasse" checked={nebenObjekte.indexOf("Terrasse") >= 0} onChange={(e) => onSetNebenobjekte(e.target.value, e.target.checked)} />Terrasse</label> 
                        {(subTyp !== 'Erdgeschoß') && <>
                            <label><input type="checkbox" id="balkon" name="nebenobjekte" value="Balkon" checked={nebenObjekte.indexOf("Balkon") >= 0} onChange={(e) => onSetNebenobjekte(e.target.value, e.target.checked)} />Balkon</label>    
                            <label><input type="checkbox" id="lift" name="nebenobjekte" value="Lift" checked={nebenObjekte.indexOf("Lift") >= 0} onChange={(e) => onSetNebenobjekte(e.target.value, e.target.checked)} />Lift</label>                                                                                                                                                                          
                        </>}
                        <label><input type="checkbox" id="kellerabteil" name="nebenobjekte" value="Kellerabteil" checked={nebenObjekte.indexOf("Kellerabteil") >= 0} onChange={(e) => onSetNebenobjekte(e.target.value, e.target.checked)} />Kellerabteil</label> 
                        <label><input type="checkbox" id="tiefgaragenstellplatz" name="nebenobjekte" value="Tiefgaragenstellplatz" checked={nebenObjekte.indexOf("Tiefgaragenstellplatz") >= 0} onChange={(e) => onSetNebenobjekte(e.target.value, e.target.checked)} />Tiefgaragenstellplatz</label>                                                                                                               
                        <label><input type="checkbox" id="sonstige" name="nebenobjekte" value="Sonstige" checked={nebenObjekte.indexOf("Sonstige") >= 0} onChange={(e) => onSetNebenobjekte(e.target.value, e.target.checked)} />Sonstige</label>                                                                                       
                    </div>                      
                </div>
            </>}
            {(immoTyp === 'Einfamilienhaus') && <>
                <div>                    
                    Bauweise:&nbsp;                     
                    <b>{bauWeise}</b>
                    <div className={"immoSelect"}>
                        <label><input type="radio" id="massiv" name="bauweise" value="Massiv" checked={bauWeise === "Massiv"} onChange={(e) => setBauweise(e.target.value)} />Massiv</label>                                           
                        <label><input type="radio" id="fertigteil" name="bauweise" value="Fertigteil" checked={bauWeise === "Fertigteil"} onChange={(e) => setBauweise(e.target.value)} />Fertigteil</label>                                                                                       
                        <label><input type="radio" id="sonstige" name="bauweise" value="Sonstige" checked={bauWeise === "Sonstige"} onChange={(e) => setBauweise(e.target.value)} />Sonstige</label>                                                                                       
                    </div>                                                               
                </div>
                <div>                    
                    Heizung:&nbsp;                     
                    <b>{heizung}</b>
                    <div className={"immoSelect"}>
                        <label><input type="radio" id="zentralWaermepumpe" name="heizung" value="Zentralheizung mit Wärmepumpe" checked={heizung === "Zentralheizung mit Wärmepumpe"} onChange={(e) => setHeizung(e.target.value)} />Wärmepumpe</label>                                                                                       
                        <label><input type="radio" id="zentralGas" name="heizung" value="Zentralheizung mit Gas" checked={heizung === "Zentralheizung mit Gas"} onChange={(e) => setHeizung(e.target.value)} />Gas</label>                                           
                        <label><input type="radio" id="zentralPellets" name="heizung" value="Zentralheizung mit Pellets" checked={heizung === "Zentralheizung mit Pellets"} onChange={(e) => setHeizung(e.target.value)} />Pellets</label>                                                                                       
                        <label><input type="radio" id="zentralOel" name="heizung" value="Zentralheizung mit Öl" checked={heizung === "Zentralheizung mit Öl"} onChange={(e) => setHeizung(e.target.value)} />Öl</label>                                                                                       
                        <label><input type="radio" id="zentralFernwärme" name="heizung" value="Zentralheizung mit Fernwärme" checked={heizung === "Zentralheizung mit Fernwärme"} onChange={(e) => setHeizung(e.target.value)} />Fernwärme</label>                                                                                       
                        <label><input type="radio" id="einzel" name="heizung" value="Einzelöfen" checked={heizung === "Einzelöfen"} onChange={(e) => setHeizung(e.target.value)} />Einzelöfen</label>                                                                                       
                        <label><input type="radio" id="sonstige" name="heizung" value="Sonstige" checked={heizung === "Sonstige"} onChange={(e) => setHeizung(e.target.value)} />Sonstige</label>                                                                                       
                    </div>                                                               
                </div>
                <div>                    
                    Nebenobjekte:&nbsp;                     
                    <b>{nebenObjekte.join()}</b>
                    <div className={"immoSelect"}>
                        <label><input type="checkbox" id="nebengebaeude" name="nebenobjekte" value="Nebengebäude" checked={nebenObjekte.indexOf("Nebengebäude") >= 0} onChange={(e) => onSetNebenobjekte(e.target.value, e.target.checked)} />Nebengebäude</label>                                           
                        <label><input type="checkbox" id="carport" name="nebenobjekte" value="Carport" checked={nebenObjekte.indexOf("Carport") >= 0} onChange={(e) => onSetNebenobjekte(e.target.value, e.target.checked)} />Carport</label>                                                                                       
                        <label><input type="checkbox" id="garage" name="nebenobjekte" value="Garage" checked={nebenObjekte.indexOf("Garage") >= 0} onChange={(e) => onSetNebenobjekte(e.target.value, e.target.checked)} />Garage</label>                                                                                       
                        <label><input type="checkbox" id="sonstige" name="nebenobjekte" value="Sonstige" checked={nebenObjekte.indexOf("Sonstige") >= 0} onChange={(e) => onSetNebenobjekte(e.target.value, e.target.checked)} />Sonstige</label>                                                                                       
                    </div>                                                               
                </div>
            </>}          
            {(immoTyp === 'Einfamilienhaus' || immoTyp === 'Wohnung') && <>
                <div>
                    Wohnfläche:&nbsp;
                    <b>{wohnFlaeche} m&sup2;</b>
                    <div className='immoSlider'><ImmoSlider min={0} max={1000} step={1} value={wohnFlaeche} sliderChange={onSliderChangeWohnflaeche} /></div> 
                </div> 
            </>}                                                        
            {(immoTyp === 'Einfamilienhaus') && <>                    
                <div>                    
                    Grundstücksfläche:&nbsp;                     
                    <b>{grundstueckFlaeche} m&sup2;</b>
                    <div className='immoSlider'><ImmoSlider min={0} max={10000} step={10} value={grundstueckFlaeche} sliderChange={onSliderChangeGrundstueckflaeche} /></div>
                </div>
            </>}
        </>
    );                    
};

export default OptionenPage;