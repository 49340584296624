import React, { createContext, useState } from 'react';

type ContextProps = { 
  page: number;
  setPage: (value: number) => void;

  immoTyp: string;
  setImmo: (value: string) => void;  
  subTyp: string;
  setSub: (value: string) => void;
  
  bauJahr: number;
  setBaujahr: (value: number) => void;
  bauWeise: string;
  setBauweise: (value: string) => void;
  heizung: string;
  setHeizung: (value: string) => void;
  nebenObjekte: Array<string>;
  setNebenobjekte: (value: Array<string>) => void;
  wohnFlaeche: number;
  setWohnflaeche: (value: number) => void;
  grundstueckFlaeche: number;
  setGrundstueckflaeche: (value: number) => void;

  objektAdresse: string;
  setAdresse: (value: string) => void;
  objektName: string;
  setName: (value: string) => void;
  objektEmail: string;
  setEmail: (value: string) => void;
  objektAnmerkungen: string;
  setAnmerkungen: (value: string) => void;

  datenschutzOk: boolean;
  setDatenschutz: (value: boolean) => void;
  submitOk: boolean;
  setSubmitted: (value: boolean) => void;
};

export const AppContext = createContext<ContextProps | undefined>(undefined);

type Props = {
  children: React.ReactNode
};

export const AppProvider = ({
  children
}: Props) => {   
  const [page, setPage] = useState(1);

  const [immoTyp, setImmo] = useState('');
  const [subTyp, setSub] = useState('');
  
  const [bauJahr, setBaujahr] = useState(1900);
  const [bauWeise, setBauweise] = useState('Massiv');
  const [heizung, setHeizung] = useState('Zentralheizung mit Wärmepumpe');
  const [nebenObjekte, setNebenobjekte] = useState(new Array<string>());
  const [wohnFlaeche, setWohnflaeche] = useState(75);
  const [grundstueckFlaeche, setGrundstueckflaeche] = useState(1000);
  
  const [objektAdresse, setAdresse] = useState('');
  const [objektName, setName] = useState('');
  const [objektEmail, setEmail] = useState('');
  const [objektAnmerkungen, setAnmerkungen] = useState('');

  const [datenschutzOk, setDatenschutz] = useState(false);
  const [submitOk, setSubmitted] = useState(false);

  return (
    <AppContext.Provider value={ {page, setPage, immoTyp, setImmo, subTyp, setSub, bauJahr, setBaujahr, bauWeise, setBauweise, heizung, setHeizung, nebenObjekte, setNebenobjekte, wohnFlaeche, setWohnflaeche, grundstueckFlaeche, setGrundstueckflaeche, objektAdresse, setAdresse, objektName, setName, objektEmail, setEmail, objektAnmerkungen, setAnmerkungen, datenschutzOk, setDatenschutz, submitOk, setSubmitted } }>
      {children}
    </AppContext.Provider>  
  );
};

export const useAppContext = () => React.useContext(AppContext);