import React, { useCallback } from 'react';
import { useAppContext } from '../AppContext';

const ImmotypPage = () => { 
    const { page, setPage, immoTyp, subTyp, setSub } = useAppContext()!;    

    const selectSub = useCallback((subTyp: string) => {
        setSub(subTyp);
        setPage(page+1);
    }, [page,setSub,setPage]);

    return (
        <>
            {immoTyp === 'Einfamilienhaus' && <>
                <div><button className={subTyp === 'Freistehend' ? 'immoButton-Selected' : 'immoButton'} id='immoButton-HausFreistehend' onClick={() => selectSub('Freistehend')}>Freistehend</button><button className={subTyp === 'Doppelhaushälfte' ? 'immoButton-Selected' : 'immoButton'} id='immoButton-HausDoppelhaus' onClick={() => selectSub('Doppelhaushälfte')}>Doppelhaushälfte</button><button className={subTyp === 'Reihenhaus' ? 'immoButton-Selected' : 'immoButton'} id='immoButton-HausReihenhaus' onClick={() => selectSub('Reihenhaus')}>Reihenhaus</button></div>                               
            </>}

            {immoTyp === 'Wohnung' && <> 
                <div><button className={subTyp === 'Erdgeschoß' ? 'immoButton-Selected' : 'immoButton'} id='immoButton-WohnungErdgeschoss' onClick={() => selectSub('Erdgeschoß')}>Erdgeschoß</button><button className={subTyp === 'Obergeschoß' ? 'immoButton-Selected' : 'immoButton'} id='immoButton-WohnungObergeschoss' onClick={() => selectSub('Obergeschoß')}>Obergeschoß</button><button className={subTyp === 'Dachgeschoß' ? 'immoButton-Selected' : 'immoButton'} id='immoButton-WohnungDachgeschoss' onClick={() => selectSub('Dachgeschoß')}>Dachgeschoß</button></div>                               
            </>}            
        </>
    );                    
};

export default ImmotypPage;