import React from 'react';
import './App.css';
import { AppProvider } from './AppContext';
import AppPage from './AppPage';

function App() {  
  return (    
      <AppProvider>      
        <AppPage />      
      </AppProvider>    
  );
}

export default App;