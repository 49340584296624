import React, { useCallback } from 'react';
import { useAppContext } from '../AppContext';

const StartPage = () => { 
    const { page, setPage, immoTyp, setImmo } = useAppContext()!;

    const selectImmo = useCallback((immoTyp: string) => {
        setImmo(immoTyp);
        setPage(page+1);
    }, [page,setImmo,setPage]);

    return (
        <div><button className={immoTyp === 'Einfamilienhaus' ? 'immoButton-Selected' : 'immoButton'} id='immoButton-Einfamilienhaus' onClick={() => selectImmo('Einfamilienhaus')}>Einfamilienhaus</button><button className={immoTyp === 'Wohnung' ? 'immoButton-Selected' : 'immoButton'} id='immoButton-Wohnung' onClick={() => selectImmo('Wohnung')}>Wohnung</button></div>
    );                    
};

export default StartPage;