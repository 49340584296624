import React, { useCallback, useState } from 'react';
import { useAppContext } from '../AppContext';
import MessageItem from "../Components/MessageItem";
import { postMessage } from "../Components/SendMail";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"

const SubmitPage = () => {     
    const [error, setError] = useState(false);
    const { promiseInProgress } = usePromiseTracker();
    const { immoTyp, subTyp, bauJahr, bauWeise, heizung, nebenObjekte, wohnFlaeche, grundstueckFlaeche, objektAdresse, objektName, objektEmail, objektAnmerkungen, datenschutzOk, setDatenschutz, submitOk, setSubmitted } = useAppContext()!;            

    const submitData = useCallback(() => {         
        const item: MessageItem = {
            subject: "Anfrage für Immobilienbewertung",
            textBody: `Immobilientyp: ${immoTyp}\nSubtyp: ${subTyp}\nBaujahr: ${bauJahr}\nBauweise: ${bauWeise}\nHeizung: ${heizung}\nNebenobjekte: ${nebenObjekte}\nWohnfläche: ${wohnFlaeche}\nGrundstücksfläche: ${grundstueckFlaeche}\nObjektadresse: ${objektAdresse}\nName: ${objektName}\nE-Mail: ${objektEmail}\nAnmerkungen: ${objektAnmerkungen}`,
            htmlBody: `<b>Immobilientyp:</b> ${immoTyp}<br/><b>Subtyp:</b> ${subTyp}<br/><b>Baujahr:</b> ${bauJahr}<br/><b>Bauweise:</b> ${bauWeise}<br/><b>Heizung:</b> ${heizung}<br/><b>Nebenobjekte:</b> ${nebenObjekte}<br/><b>Wohnfläche:</b> ${wohnFlaeche}<br/><b>Grundstücksfläche:</b> ${grundstueckFlaeche}<br/><b>Objektadressep:</b> ${objektAdresse}<br/><b>Name:</b> ${objektName}<br/><b>E-Mail:</b> ${objektEmail}<br/><b>Anmerkungen:</b> ${objektAnmerkungen}`,
            senderEmail: "christian.cerny@live.at",
            senderName: "Christian Cerny",    
            recipientEmail: "sabine.cerny-weber@remax-balance.at",
            recipientName: "Sabine Cerny-Weber"   
        }
        trackPromise(
            postMessage(item).then(ok => setSubmitted(ok)).catch(error => setError(true))
        );        
    }, [immoTyp, subTyp, bauJahr, bauWeise, heizung, nebenObjekte, wohnFlaeche, grundstueckFlaeche, objektAdresse, objektName, objektEmail, objektAnmerkungen, setSubmitted, setError]);

    return (
        <>  
            {(promiseInProgress) && <>            
                <Loader type="Oval" color="#003DA5" height={50} width={50} />
                Daten werden gesendet...                           
            </>}

            {(error) && <>            
                <div className={'immoAdresse'}>
                    Leider gab es beim Versenden Ihrer Anfrage ein technisches Problem. Bitte wenden Sie sich direkt an <a href="mailto:office@remax-balance.at">office@remax-balance.at</a>!
                </div>                                    
            </>}

            {(submitOk && error === false && promiseInProgress === false) && <>            
                <div className={'immoAdresse'}>
                    Vielen Dank für Ihre Anfrage. Sie werden so schnell wie möglich von einem Remax Balance-Makler kontaktiert.
                </div>                                    
            </>}

            {(submitOk === false && error === false && promiseInProgress === false) && <>                                                
                <div className={'immoAdresse'}>
                    Mit dem Bestätigen der Checkbox und dem Absenden sind Sie damit einverstanden, dass Ihre zuvor eingegeben Daten an Remax Balance weitergeleitet werden 
                    und Sie von dem für Ihre Objektadresse zuständigen Remax Balance-Makler mit einem individuellen Bewertungsangebot per E-Mail kontaktiert werden.
                </div>
                <div className={'immoAdresse'}>
                    <label><input type="checkbox" id="datenschutz" name="datenschutz" value="Datenschutz" checked={datenschutzOk} onChange={(e) => setDatenschutz(e.target.checked)} />Ich erkläre mich mit der Verwendung meiner Daten zum Zwecke der Erstellung und Übersendung einer Immobilienbewertung und mit der <a href="https://www.remax.at/de/datenschutz" rel="noopener noreferrer" target="_blank">Datenschutzerklärung</a> einverstanden.</label>                                           
                </div>        
                {(datenschutzOk) && <>                                                
                    <button className={'immoSubmitButton'} onClick={submitData}>Absenden</button>            
                </>}
            </>}
        </>
    );                    
};

export default SubmitPage;