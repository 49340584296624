import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

type SliderProps = { 
    min: number;
    max: number;
    step: number;
    value: number;
    sliderChange: (value: number) => void;
}

const ImmoSlider = (props: SliderProps) => { 
    return (    
        <Slider min={props.min} max={props.max} step={props.step} value={props.value} style={{}} trackStyle={{ backgroundColor: '#4184CD'}} handleStyle={{ borderColor: '#4184CD', backgroundColor: 'white' }} onChange={(value) => props.sliderChange(value)} />        
    );
}

export default ImmoSlider;