import React from 'react';
import { useAppContext } from '../AppContext';

const AdressePage = () => { 
    const { objektAdresse, setAdresse, objektName, setName, objektEmail, setEmail, objektAnmerkungen, setAnmerkungen } = useAppContext()!;            

    return (
        <>                                                
            <div>Objektadresse:</div>
            <div><input type='text' className='immoAdresse' value={objektAdresse} onChange={(e) => setAdresse(e.target.value)} /></div>
            <div>Name:</div>
            <div><input type='text' className='immoAdresse' value={objektName} onChange={(e) => setName(e.target.value)} /></div> 
            <div>E-Mail:</div>
            <div><input type='email' className='immoAdresse' value={objektEmail} onChange={(e) => setEmail(e.target.value)} /></div> 
            <div>Anmerkungen:</div>
            <div><textarea name='anmerkungen' cols={20} rows={5} className='immoAdresse' value={objektAnmerkungen} onChange={(e) => setAnmerkungen(e.target.value)} /></div> 
        </>
    );                    
};

export default AdressePage;