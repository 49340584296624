import React from 'react';
import { useAppContext } from './AppContext';
import StartPage from './Pages/StartPage';
import ImmotypPage from './Pages/ImmotypPage';
import OptionenPage from './Pages/OptionenPage';
import AdressePage from './Pages/AdressePage';
import SubmitPage from './Pages/SubmitPage';

const AppPage = () => { 
    const { page, setPage, submitOk } = useAppContext()!;               
        
    return (
        <div className="App">                                              
            <div className={'immoStepTitle'}>Schritt {page}</div>

            {page === 1 && <> 
                <StartPage />
            </>}        

            {page === 2 && <> 
                <ImmotypPage /> 
            </>}

            {page === 3 && <> 
                <OptionenPage />                                                              
            </>} 

            {page === 4 && <> 
                <AdressePage />               
            </>}

            {page === 5 && <> 
                <SubmitPage />               
            </>}

            {page > 1 && <>                 
                <div className={'immoBackButton'}>
                    {submitOk === false && <> 
                        <button className={'immoBackButton'} onClick={() => setPage(page-1)}>Zurück</button>
                    </>}
                    {page >= 3 && page < 5 && <> 
                    <button className={'immoBackButton'} onClick={() => setPage(page+1)}>Weiter</button>
                    </>}
                </div>
            </>}            
        </div>  
    );                    
};

export default AppPage;